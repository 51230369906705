import { formatMonth, formatMonthTrailingNumber, formatQuarterSolo, formatYear, parseDate } from "utils/date";
import { addMonths, differenceInMonths } from "date-fns";

export function getInitials(name) {
  const items = name.split(" ");
  return items[0][0].toUpperCase() + items[1][0].toUpperCase();
}

function getStatusIndicator(status, availableStatuses) {
  const index = availableStatuses.indexOf(status);
  return {
    current: { value: status, label: status, index: index + 1 },
    index: index + 1,
    max: availableStatuses.length,
    statuses: availableStatuses.map((item, idx) => ({ label: item, value: item, index: idx + 1 })),
  };
}

function getPeriodIndicator(periodicity, periodStart, periodEnd) {
  if (periodicity === "monthly") {
    return formatMonthTrailingNumber(periodStart);
  }
  if (periodicity === "quarterly") {
    return formatQuarterSolo(periodStart);
  }
  const indicator = formatYear(periodStart);
  if (periodStart.slice(0, 4) !== periodEnd.slice(0, 4)) {
    return `${indicator}/${periodEnd.slice(2, 4)}`;
  }
  return indicator;
}

export function prepareData(templates, events, hideUnusedTemplates = true) {
  const data = { templates: [], companies: [] };
  const companies = {};

  const emptyTemplateList = templates.reduce(
    (item, tpl) => ({
      ...item,
      [tpl.id]: { id: `t.${tpl.id}`, disabled: true, name_en: tpl.name_en, name_sv: tpl.name_sv },
    }),
    {}
  );

  const temp = {};
  const usedTemplates = new Set();
  events.forEach((event) => {
    if (!temp[event.company_id]) {
      temp[event.company_id] = {};
    }
    if (!companies[event.company_id]) {
      companies[event.company_id] = {
        id: event.company_id,
        name: event.company_name,
        involved: [],
        events: { ...emptyTemplateList },
      };
    }
    if (!temp[event.company_id][event.assignee_id]) {
      // need unique
      companies[event.company_id].involved.push({
        name: event.assignee_name,
        initials: getInitials(event.assignee_name),
      });
      temp[event.company_id][event.assignee_id] = 1;
    }
    const template = emptyTemplateList[event.activity.template];
    usedTemplates.add(event.activity.template);
    companies[event.company_id].events[event.activity.template] = {
      ...event,
      deadline: parseDate(event.deadline),
      activity: {
        ...event.activity,
        name_en: template.name_en,
        name_sv: template.name_sv,
      },
      disabled: false,
      template_name: emptyTemplateList[event.activity.template].name,
      periodIndicator: getPeriodIndicator(event.periodicity, event.period_start, event.period_end),
      statusIndicator: getStatusIndicator(event.status, event.activity.available_statuses),
    };
  });
  if (hideUnusedTemplates === false) {
    data.templates = templates;
  } else {
    data.templates = templates.filter((template) => usedTemplates.has(template.id));
  }

  data.companies = Object.values(companies).sort((a, b) => a.name.localeCompare(b.name));
  return data;
}

function monthsInPeriod(startPeriod, endPeriod) {
  let period = startPeriod;
  const periods = [];
  while (period < endPeriod) {
    periods.push(period);
    period = addMonths(period, 1);
  }

  return periods;
}

export function prepareOverviewData(templates, year, events) {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year, 11, 31);
  const emptyMonths = monthsInPeriod(startDate, endDate);
  const emptyMonthsDict = emptyMonths.reduce(
    (item, month) => ({
      ...item,
      [formatMonth(month, "yyyy-MM")]: { key: formatMonth(month, "yyyy-MM"), disabled: true },
    }),
    {}
  );
  const data = { templates: [], months: emptyMonths };
  data.templates = templates.reduce(
    (item, tpl) => ({
      ...item,
      [tpl.id]: { ...tpl, hasEvents: false, months: { ...emptyMonthsDict } },
    }),
    {}
  );
  let periodStart;
  let colSpan;
  events.forEach((event) => {
    periodStart = formatMonth(event.period_start, "yyyy-MM");
    if (event.periodicity === "monthly") {
      colSpan = 1;
    } else if (event.periodicity === "quarterly") {
      colSpan = 3;
    } else if (event.periodicity === "yearly_eu" || event.periodicity === "yearly_se") {
      if (event.period_start.getFullYear() < year) {
        colSpan = differenceInMonths(event.period_end, new Date(year, 0, 1)) + 1;
        periodStart = `${year}-01`;
      } else if (event.period_end.getFullYear() > year) {
        colSpan = differenceInMonths(new Date(year, 11, 31), event.period_start) + 1;
      } else {
        colSpan = differenceInMonths(event.period_end, event.period_start) + 1;
      }
    } else {
      colSpan = 12;
    }
    data.templates[event.activity.template].months[periodStart] = {
      key: periodStart,
      disabled: false,
      colSpan,
      ...event,
      statusIndicator: getStatusIndicator(event.status, event.activity.available_statuses),
    };
    data.templates[event.activity.template].hasEvents = true;
    const monthNumber = parseInt(periodStart.slice(5), 10);
    for (let i = 1; i < colSpan; i++) {
      const key = `${year}-${(monthNumber + i).toString().padStart(2, "0")}`;
      delete data.templates[event.activity.template].months[key];
    }
  });
  Object.entries(data.templates).forEach((entry) => {
    // remove template/rows which does not have events
    const key = entry[0];
    const { hasEvents } = entry[1];
    if (!hasEvents) {
      delete data.templates[key];
    }
  });
  return data;
}
