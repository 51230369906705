import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { HistoryButton, SubmitButton } from "components/ui/buttons";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedDocuments } from "components/ui/documents-refactored/documents";
import { COMPANY_ARCHIVE_ALLOWED_FILE_TYPES } from "components/ui/documents/DropZoneInput";
import * as documentAPI from "api/document";
import { AllError, FormGroup } from "components/formik";
import * as documentsAPI from "api2/documents";
import archiveCategories, { categoryByValue } from "utils/calc/companyArchiveCategories";
import { archiveAuditorCategories } from "api/options";
import { formatDate, isSameDayOrAfter, isSameDayOrBefore, parseDate } from "../../../utils/date";
import { useCompanyState } from "../../../hooks/useCompany";

function ShareButton({ setFieldValue, forAuditor, forCustomer, disableAuditor, isConsult }) {
  const { t } = useTranslation("common");
  return (
    <div className="form-group">
      <label className="form-label">{t("common:actions.share")}</label>
      <div className="d-flex" style={{ height: 36 }}>
        <Button
          variant="toggle"
          active={forAuditor}
          disabled={disableAuditor}
          onClick={() => setFieldValue("for_auditor", !forAuditor)}
        >
          <i className="fe-clipboard mr-1" /> {t("company:auditor")}
        </Button>
        <Button
          className="ml-2 mr-3"
          variant="toggle"
          active={forCustomer || !isConsult}
          disabled={!isConsult}
          onClick={() => setFieldValue("for_customer", !forCustomer)}
        >
          <i className="fe-user mr-1" /> {t("common:customer")}
        </Button>
        {forCustomer && (
          <FormGroup.LabeledCheckbox
            label={t("common:actions.notifyCustomer")}
            name="notify_customer"
            wrapperClass="pl-1"
          />
        )}
      </div>
    </div>
  );
}

function ExtendedMonthPicker() {
  const { t } = useTranslation("common");
  const { financialYears } = useCompanyState();
  const {
    values: { month },
    setFieldValue,
  } = useFormikContext();
  useEffect(() => {
    if (month) {
      const newYear = financialYears.find(
        (fyear) => isSameDayOrAfter(month, fyear.date_start) && isSameDayOrBefore(month, fyear.date_end)
      );
      if (newYear) {
        setFieldValue("financial_year", newYear.id);
      }
    }
  }, [month, financialYears, setFieldValue]);

  return <FormGroup.DateMonthPicker isClearable name="month" label={t("common:dates.month")} />;
}

function CompanyArchiveForm({ companyId, archive, onSave }) {
  const { t } = useTranslation("common");
  const { perms } = useCompanyState();
  const isConsult = perms.isAdmin || perms.isAgencyConsult;
  const isEmployee = { perms };
  const [newFiles, setNewFiles] = useState(!archive.id && [{ key: _.uniqueId("nd.") }]);
  const archiveAuditorCategoriesList = archiveAuditorCategories.asList();
  const formikProps = {
    initialValues: {
      ...archive,
      category: categoryByValue(archive.category) || archiveCategories[0].options[9],
      auditor_category: archiveAuditorCategories.getOption(archive.auditor_category) || "",
      notify_customer: false,
      month: archive.month ? parseDate(archive.month) : null,
    },
    validationSchema: yup.object().shape({
      title: newFiles.length >= 2 ? yup.string().notRequired() : yup.string().required(),
      category: yup.object().nullable().required(),
      auditor_category: yup
        .object()
        .nullable()
        .when("for_auditor", ([for_auditor], schema, { parent }) => {
          return for_auditor && parent.category?.type === "Other" ? schema.required() : schema;
        }),
      financial_year: yup
        .number()
        .nullable()
        .when("for_auditor", ([for_auditor], schema) => {
          return for_auditor ? schema.required() : schema;
        }),
    }),
    onSubmit: async (values, { setErrors }) => {
      let _newFiles = [];
      if (!archive.id) {
        _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
        if (!_newFiles.length) {
          toast.error(t("msg:fixErrors"));
          setErrors({ __all__: t("file.noFile") });
          return false;
        }
      }
      let auditorCat = values.auditor_category ? values.auditor_category.value : "";
      if (values.category.type !== "Other" || values.for_auditor === false) {
        auditorCat = "";
      }
      return documentsAPI.companyDocuments
        .save(companyId, _newFiles, {
          ...values,
          title: values.title,
          category: values.category.value,
          auditor_category: auditorCat,
          financial_year: values.financial_year ? values.financial_year : "",
          for_customer: isConsult ? values.for_customer : true,
          notify_customer: isConsult ? values.notify_customer : false,
          month: values.month ? formatDate(values.month) : null,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((errors) => {
          toast.error(t("msg:fixErrors"));
        });
    },
  };

  function onFileSelected(file, index, setFieldValue, currentTitle) {
    const maxCount = 10;
    if (file) {
      const fileCount = file.length + newFiles.length - 1;
      if (fileCount > maxCount) {
        toast.error(t("msg:fileLengthLimit", { max: maxCount }));
        return;
      }
    }
    documentAPI.onNewFileChange({
      file,
      index,
      allFiles: newFiles,
      setFile: setNewFiles,
      max: maxCount,
    });
    if (newFiles.length >= 2 || file?.length >= 2) {
      setFieldValue("title", "");
    } else if (!currentTitle && file && file[0]) {
      setFieldValue("title", file[0].name);
    }
  }

  const onCategoryChange = (selected, setFieldValue) => {
    if (selected.type !== "Other") {
      setFieldValue("for_auditor", true);
    } else {
      setFieldValue("for_auditor", false);
    }
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setFieldValue }) => {
        return (
          <Form id="archiveForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={6}>
                    <Row>
                      <Col md={9}>
                        <FormGroup.Input
                          label={t("common:name")}
                          name="title"
                          required={newFiles.length - 1 <= 1}
                          disabled={newFiles.length - 1 >= 2}
                        />
                      </Col>
                      {archive.id && (
                        <Col md={3}>
                          <div className="d-flex flex-column align-items-end">
                            <label>&nbsp;</label>
                            <HistoryButton
                              apiResource={documentsAPI.companyDocuments.history}
                              apiParams={[companyId, archive.id]}
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <FormGroup.SimpleSelect
                          options={archiveCategories}
                          name="category"
                          label={t("common:category")}
                          getOptionLabel={(option) => t(`options:cdc.${option.value}`)}
                          formatGroupLabel={(group) => t(`options:cdc.${group.label}`)}
                          onChange={(selected) => onCategoryChange(selected, setFieldValue)}
                        />
                      </Col>
                      <Col lg={3}>
                        <ExtendedMonthPicker />
                      </Col>
                      <Col>
                        <FormGroup.FinancialYearPicker
                          label={t("common:financialYear")}
                          name="financial_year"
                          required={values.for_auditor}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup.Input as="textarea" label={t("common:description")} name="description" rows={3} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ShareButton
                          setFieldValue={setFieldValue}
                          forAuditor={values.for_auditor}
                          forCustomer={values.for_customer}
                          isConsult={isConsult}
                          isEmployee={isEmployee}
                          isDraft={!!values.id}
                          disableAuditor={values.category.type !== "Other"}
                        />
                      </Col>
                    </Row>
                    {values.for_auditor && values.category.type === "Other" && (
                      <Row>
                        <Col>
                          <FormGroup.SimpleSelect
                            options={archiveAuditorCategoriesList}
                            name="auditor_category"
                            label={t("common:auditorCategory")}
                            required={values.for_auditor && values.category.type === "Other"}
                          />
                        </Col>
                      </Row>
                    )}
                    <AllError errors={errors} />
                    <Row>
                      <Col>
                        <hr />
                        <ButtonGroup>
                          <SubmitButton isSubmitting={isSubmitting} />
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} className="verification-files">
                    {archive.id && (
                      <SavedDocuments
                        companyId={companyId}
                        documents={[archive.id]}
                        partUrl="/documents/company"
                        removable={false}
                      />
                    )}
                    <div>
                      <i className="fas fa-info-circle mr-1" />
                      {t("infoAboutMultiUpload")}
                    </div>
                    <NewDocuments
                      fileSize={1024 * 1024 * 20}
                      fileTypes={COMPANY_ARCHIVE_ALLOWED_FILE_TYPES}
                      documents={newFiles}
                      onChange={({ file, index }) => onFileSelected(file, index, setFieldValue, values.title)}
                      multiple
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CompanyArchiveForm;
